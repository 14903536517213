<template>
  <b-card>
    <b-tabs>
      <b-tab title="Configuración" active>
        <config-tab :configProtocol="configProtocol" @postDataProtocol="postDataProtocol"></config-tab>
      </b-tab>
      <!-- <b-tab title="Historial"><p>I'm the second tab</p></b-tab> -->
    </b-tabs>
  </b-card>
</template>
<script>
import ConfigTab from './components/ConfigTab.vue'
import BaseServices from '@/store/services/index'
export default {
  components: {ConfigTab},
  data() {
    return {
      baseServices: new BaseServices(),
      configProtocol: {
        retryProtocol: false,
        returnProtocol: false,
        loading: true
      },
      shipperCode: 0
    }
  },
  mounted() {
    this.getAllData()
  },
  methods: {
    getAllData() {
      // this.loading.first = true
      this.shipperCode = this.$session.get('cas_user')?.shipper?.id && !this.$route.params?.id ? this.$session.get('cas_user').shipper.id : this.$route.params.id
      if (this.shipperCode) {
        document.getElementById('loading-bg').style.display = 'block'
        const params = {
          shipper_id: this.shipperCode
        }
        const queryParams = {
          shipper_id: this.shipperCode
        }
        const arrServices = [
          {
            name: 'getProtocolConfig',
            queryParams: {
              ...queryParams
            },
            params: {
              ...params
            }
          }]
        this.baseServices.callMultipleServices(arrServices, true)
          .then((response) => {
            this.setDataProtocol(response.getProtocolConfig.data)
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            document.getElementById('loading-bg').style.display = 'none'
          })
      }
    },
    setDataProtocol(data) {
      this.configProtocol.retryProtocol = !!data.retry_protocol
      this.configProtocol.returnProtocol = !!data.return_protocol
      this.configProtocol.loading = false
    },
    postDataProtocol(data) {
      if (data) {
        this.baseServices.callService('postProtocolConfig', data, {shipper_id: this.shipperCode})
          .then((response) => {
            if (response) {
              this.setDataProtocol(response.data)
              this.$success(this.$t('Configuración agregada.'))
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    }
  }
  
}
</script>